<template>
  <div class="tween-number-container">
    <div class="tween-number-container--increase-body">
      <div class="progress-bar" :style="{ opacity: progressBarWidth }"></div>
      <label for="inputNumber">Escoge un número:</label>
      <div class="number-input">
        <button @click="decrement" class="btn">-</button>
          <div class="display">{{ tweened.number.toFixed(0) }}</div>
        <button @click="increment" class="btn">+</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, watch } from 'vue';
import gsap from 'gsap';

export default {
  name: 'TweenNumber',
  setup() {
    const number = ref(0);

    const tweened = reactive({
      number: 0,
    });

    const increment = () => {
      if (number.value < 500) {
        number.value += 25;
      }
    };

    const decrement = () => {
      if (number.value > 0) {
        number.value -= 25;
      }
    };

    const progressBarWidth = computed(() => {
      const percentage = (tweened.number / 500) * 100; // Basado en un máximo de 500
      return `${percentage}%`;
    });

    watch(number, (newVal) => {
      gsap.to(tweened, {
        duration: 0.5,
        number: Number(newVal) || 0,
      });
    });

    return {
      number,
      tweened,
      increment,
      decrement,
      progressBarWidth,
    };
  },
};
</script>

<style lang="scss" scoped>
.tween-number-container {
    align-items: center;  
    border: 1px solid #ccc;
    border-radius: 24px;
    box-shadow: 1px 1px 10px 3px #d7d7d7;
    background-image: url('../assets/noise-example.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 60vh;
    margin: 20px auto;
    max-width: 500px;
    max-height: 400px;
    padding: 48px 32px;
    position: relative;
    justify-content: center;
    text-align: center;
    width: 80vw;

    &--increase-body {
      align-items: center;
      backdrop-filter: blur(30px);
      background: radial-gradient(41.19% 34.46% at 10.29% 5.26%, rgba(64, 69, 88, 0.77) 0%, rgba(14, 15, 19, 0.77) 100%);
      border-radius: 24px;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 60%;
      justify-content: center;
      opacity: 0.9;
      overflow: hidden;
      padding: 48px 72px;
      width: 60%;

    .progress-bar {
      bottom: 0;
      background-color: #000000;
      height: 100%;
      position: absolute;
      transition: all 0.3s ease-in-out;
      left: 0;
      width: 100%;
      z-index: -3;
      }
    }

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .number-input {
    align-items: center;
    display: flex;
    gap: 24px;
    

    .btn {
      align-items: center;
      background: #272727;
      border: none;
      border-image: linear-gradient(to bottom, #555, #222) 1;
      border-radius: 50%;
      box-shadow: 
        0px 4px 8px rgba(0, 0, 0, 0.7), // Sombra principal hacia abajo
        inset 0px 2px 4px rgba(255, 255, 255, 0.1); // Sombra interna sutil arriba;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-size: 1.2rem;
      font-weight: bold;
      height: 64px;
      justify-content: center;
      padding: 15px;
      text-align: center;
      transition: background-color 0.3s ease;
      width: 64px;
      
      outline: none;
      &:focus {
        outline: none; // Si está enfocado, también elimina el borde
      }
      &:focus-visible {
        outline: none; // En navegadores modernos que usan focus-visible
      }

      &:hover {
        background-color: #333;
      }

      &:active {
        transform: scale(0.9); // Reduce ligeramente el tamaño para el efecto
        box-shadow: 
          0px 2px 6px rgba(0, 0, 0, 0.7), // Reduce la sombra externa
          inset 0px 4px 6px rgba(0, 0, 0, 0.4); // Incrementa la sombra interna
      }
    }

    .display {
      font-size: 2rem;
      font-weight: bold;
      width: 50px;
      text-align: center;
    }
  }

  p {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
  }
}
</style>