<template>
  <section class="row-accordion-section">
    <div class="row-accordion-section--card-1">
      <div 
        class="row-accordion-section--card-1--head-1" 
        @click="toggleContainer(1)"
      >
        <span>First Container</span>
        <div class="row-accordion-section--card-1--head-1--icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 30"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.8624 14.5714L15.7196 9.42865L20.4339 4.71429L18.7196 3L14.0053 7.71436L11.0052 4.7143L9.29092 6.42859L23.8624 21L25.5766 19.2857L22.5766 16.2857L27.2909 11.5714L25.5766 9.85716L20.8624 14.5714ZM21.7198 20.5714L9.71983 8.57143L5.40826 12.883C4.00238 14.2889 4.00238 16.5683 5.40826 17.9742L6.29122 18.8571L5.42538 19.723C4.95675 20.1916 4.95676 20.9514 5.42538 21.42L6.29118 22.2858L3.29127 25.2857L5.00556 27L8.00547 24.0001L8.87119 24.8658C9.33982 25.3344 10.0996 25.3344 10.5682 24.8658L11.4341 24L12.3171 24.883C13.723 26.2889 16.0024 26.2889 17.4083 24.883L21.7198 20.5714Z"></path></svg>
        </div>
      </div>
      <div 
        class="row-accordion-section--card-1--container-1"
        :style="{ height: isOpen[1] ? '0px' : '240px' }"
      >
        <h1>First class Amenities</h1>
        <span>Business planning, design and enjoyment.</span>
      </div>

      <div 
        class="row-accordion-section--card-1--head-1" 
        @click="toggleContainer(2)"
      >
        <span>Second Container</span>
        <div class="row-accordion-section--card-1--head-1--icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 60"><path fill-rule="evenodd" clip-rule="evenodd" d="M44.3474 12.8351C44.3474 12.3514 44.057 11.915 43.6108 11.7282L30.3849 6.19176C30.0916 6.06897 29.7615 6.06763 29.4672 6.18803L15.9418 11.7211C15.4908 11.9056 15.1962 12.3445 15.1962 12.8318V25.9583L2.68455 31.1612C2.23696 31.3473 1.94531 31.7845 1.94531 32.2692V46.8016C1.94531 47.2827 2.23274 47.7174 2.67552 47.9058L16.5302 53.8003C16.8299 53.9278 17.1685 53.928 17.4684 53.8009L29.7715 48.585L42.0746 53.8009C42.3745 53.928 42.7131 53.9278 43.0128 53.8003L56.8675 47.9058C57.3103 47.7174 57.5977 47.2827 57.5977 46.8016V32.2692C57.5977 31.7845 57.3061 31.3473 56.8585 31.1612L44.3474 25.9583V12.8351ZM41.1081 49.5532V39.2571L31.6855 35.2624V45.5585L41.1081 49.5532ZM27.8575 20.2663L18.7297 16.3544V26.0324L27.8575 29.6836V20.2663ZM5.4788 34.9644L14.9014 38.9733V49.2673L5.4788 45.2584V34.9644ZM34.1915 32.4869L42.5417 36.027L51.1346 32.3711L42.6665 29.0968L34.1915 32.4869ZM29.6325 17.1827L20.9839 13.4762L29.9113 9.82408L38.5613 13.445L29.6325 17.1827ZM16.8768 29.097L25.3518 32.487L17.0019 36.027L8.40899 32.3711L16.8768 29.097Z"></path></svg>
        </div>
      </div>
      <div 
        class="row-accordion-section--card-1--container-1"
        :style="{ height: isOpen[2] ? '240px' : '0px' }"
      >
        <h1>A <strong>joyful experience</strong></h1>
        <div class="airplane-container">
          <p>WTF</p>
          <div class="airplane-container--line"></div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 25" class="ProdCard__Plane-sc-nmglj6-9 hqnGmO"><path d="M0.936048 6.73908C0.923518 6.66406 0.981369 6.59574 1.05743 6.59574L1.95588 6.59574C1.99039 6.59574 2.02331 6.61023 2.04662 6.63567L5.77431 10.7043C5.79763 10.7297 5.83055 10.7442 5.86505 10.7442L10.4939 10.7442C10.575 10.7442 10.634 10.667 10.6126 10.5887L7.95302 0.844926C7.93165 0.766632 7.99058 0.689453 8.07174 0.689453L9.83914 0.689453C9.87767 0.689453 9.91397 0.707491 9.93723 0.738194L17.5186 10.7431C17.5192 10.7438 17.52 10.7442 17.5208 10.7442L24.9638 10.7442C25.9282 10.7442 26.71 11.526 26.71 12.4904C26.71 13.4548 25.9282 14.2366 24.9638 14.2366L17.5208 14.2366C17.52 14.2366 17.5192 14.237 17.5186 14.2377L9.93723 24.2426C9.91397 24.2733 9.87767 24.2913 9.83914 24.2913L8.07174 24.2913C7.99058 24.2913 7.93165 24.2142 7.95302 24.1359L10.6126 14.392C10.634 14.3138 10.575 14.2366 10.4939 14.2366L5.86505 14.2366C5.83055 14.2366 5.79763 14.2511 5.77431 14.2765L2.04662 18.3451C2.02331 18.3706 1.99039 18.385 1.95588 18.385L1.05743 18.385C0.981369 18.385 0.923518 18.3167 0.936048 18.2417L1.89318 12.5107C1.89543 12.4972 1.89543 12.4835 1.89318 12.4701L0.936048 6.73908Z" fill="#4A5357"></path></svg>
          <p>INT</p>
        </div>
        <span>That prepares you for scale.</span>
      </div>

      <div 
        class="row-accordion-section--card-1--head-1" 
        @click="toggleContainer(3)"
      >
        <span>Third Container</span>
        <div class="row-accordion-section--card-1--head-1--icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><mask id="bolt_svg__mask0_2818_2884" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32" style="mask-type: alpha;"><rect width="32" height="32" fill="#D9D9D9"></rect></mask><g mask="url(#bolt_svg__mask0_2818_2884)"><path d="M16 20.7998L20.2666 14.6665H16.4666L19.1333 5.33317H12V15.9998H16V20.7998ZM13.3333 29.3332V18.6665H9.33331V2.6665H22.6666L20 11.9998H25.3333L13.3333 29.3332Z"></path></g></svg>
        </div>
      </div>
      <div 
        class="row-accordion-section--card-1--container-1"
        :style="{ height: isOpen[3] ? '240px' : '0px' }"
      >
        <h1>Get the insights <strong>you need </strong></h1>
        <span>Clear explanations for your drivers.</span>
      </div>
    </div>
  </section>
</template>
  
  <script>
  export default {
    name: "CardAvatar",
    data() {
      return {
        isOpen: {
          1: false,
          2: false,
        },
      };
    },
    methods: {
      toggleContainer(cardNumber) {
        this.isOpen[cardNumber] = !this.isOpen[cardNumber];
      },
    },
  };
  </script>
  
<style lang="scss" scoped>
    .row-accordion-section {
        margin: 32px auto;
        max-width: 600px;

        @media (max-width: 780px) {
          flex-direction: column;
          height: 100vh;
        }

        &--card-1 {
          background-color: white;
          color: #192227;
          border-radius: 24px;
          border: 1px solid #ebebeb;
          text-align: left;
          flex: 2;
          overflow: hidden;
          padding: 0px;
          height: auto;
          
          .airplane-container {
            align-items: center;
            display: flex;
            gap: 4px;
            padding: 0px 48px;
            position: relative;

            &--line {
              height: 2px;
              border-bottom: 1px dashed rgb(74, 83, 87);
              width: 100%;
            }

            svg {
              position: absolute;
              width: 32px;
              left: 50%;
            }
          }

          &--head-1 {
            align-items: center;
            background-color: white;
            border-bottom: 1px solid #ebebeb;
            color: #192227;
            display: flex;
            cursor: pointer;
            padding: 12px 24px;

            &:hover {
              background-color: #ebebeb;
              opacity: 0.8;

              svg {
                color: #ebebeb;
              }
            }

            span {
              flex: 1;
            }

            &--icon {
              width: 24px;
              color: #192227;
            }

            span {
              font-size: 18px;
            }
          }

          &--container-1 {  
            display: flex;
            flex-direction: column;
            height: 0px;
            transition: all 0.3s ease-in-out;
            margin-bottom: 4px;
            overflow: hidden;
            position: relative;
            bottom: auto;

            h1 {
              font-size: 52px;
              font-weight: 500;
              line-height: 44px;
              margin: 0 0 24px 0;
              padding: 32px 48px 0px 48px;

              strong {
                color: #ade988;
              }
            }

            span {
              color: #f9a600;
              font-size: 16px;
              padding: 0px 48px 24px 48px;
            }
          }
        }

    }

</style>