<template>
    <div class="player">
        <div class="player__content">
            <div class="player__content__sound">
                <div class="player__content__sound__track">
                    <h4 class="player__content__sound__track__title">Geek Tragedy</h4>
                    <span class="player__content__sound__track__artist">The Wompats</span>
                </div>

                <div class="player__content__sound__timer">
                    <div class="player__content__sound__timer__bar">
                        <div class="player__content__sound__timer__bar__progress"></div>
                    </div>
                    <div class="player__content__sound__timer__time">
                        <span class="player__content__sound__timer__time__start">1:22</span>
                        <span class="player__content__sound__timer__time__end">4:22</span>
                    </div>
                </div>
            </div>

            <img class="player__content__img" src="https://plus.unsplash.com/premium_photo-1682125853703-896a05629709?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bXVzaWN8ZW58MHx8MHx8fDA%3D" alt="">
        </div>
        
        <div class="player__controls">
            <button class="player__controls__aleatory">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.295 9.705L13.085 11.5H9.775L7.59 8L9.775 4.5H13.085L11.295 6.295L12 7L15 4L12 1L11.295 1.705L13.085 3.5H9.775C9.6059 3.50051 9.4397 3.54389 9.29192 3.62608C9.14414 3.70828 9.01962 3.8266 8.93 3.97L7 7.055L5.07 3.97C4.98038 3.8266 4.85586 3.70828 4.70808 3.62608C4.5603 3.54389 4.3941 3.50051 4.225 3.5H1V4.5H4.225L6.41 8L4.225 11.5H1V12.5H4.225C4.3941 12.4995 4.5603 12.4561 4.70808 12.3739C4.85586 12.2917 4.98038 12.1734 5.07 12.03L7 8.945L8.93 12.03C9.01962 12.1734 9.14414 12.2917 9.29192 12.3739C9.4397 12.4561 9.6059 12.4995 9.775 12.5H13.085L11.295 14.295L12 15L15 12L12 9L11.295 9.705Z"/>
                </svg>
            </button>
            <button class="player__controls__prev">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2H2V14H1V2ZM13.2495 13.9325C13.3256 13.9767 13.412 13.9999 13.5 13.9999C13.6326 13.9999 13.7598 13.9472 13.8536 13.8535C13.9473 13.7597 14 13.6325 14 13.4999V2.49992C13.9999 2.41213 13.9768 2.3259 13.9328 2.24991C13.8889 2.17391 13.8257 2.11083 13.7496 2.06701C13.6735 2.02318 13.5873 2.00015 13.4995 2.00024C13.4117 2.00033 13.3255 2.02354 13.2495 2.06752L3.7495 7.56752C3.67373 7.61147 3.61084 7.67456 3.56712 7.75046C3.5234 7.82637 3.50039 7.91243 3.50039 8.00002C3.50039 8.08762 3.5234 8.17367 3.56712 8.24958C3.61084 8.32548 3.67373 8.38857 3.7495 8.43252L13.2495 13.9325Z"/>
                </svg>
            </button>
            <button class="player__controls__play">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 3.75H7.5C7.83152 3.75 8.14946 3.8817 8.38388 4.11612C8.6183 4.35054 8.75 4.66848 8.75 5V15C8.75 15.3315 8.6183 15.6495 8.38388 15.8839C8.14946 16.1183 7.83152 16.25 7.5 16.25H6.25C5.91848 16.25 5.60054 16.1183 5.36612 15.8839C5.1317 15.6495 5 15.3315 5 15V5C5 4.66848 5.1317 4.35054 5.36612 4.11612C5.60054 3.8817 5.91848 3.75 6.25 3.75ZM12.5 3.75H13.75C14.0815 3.75 14.3995 3.8817 14.6339 4.11612C14.8683 4.35054 15 4.66848 15 5V15C15 15.3315 14.8683 15.6495 14.6339 15.8839C14.3995 16.1183 14.0815 16.25 13.75 16.25H12.5C12.1685 16.25 11.8505 16.1183 11.6161 15.8839C11.3817 15.6495 11.25 15.3315 11.25 15V5C11.25 4.66848 11.3817 4.35054 11.6161 4.11612C11.8505 3.8817 12.1685 3.75 12.5 3.75Z"/>
                </svg>
            </button>
            <button class="player__controls__next">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 2H15V14H14V2ZM2.14645 13.8537C2.24021 13.9474 2.36739 14.0001 2.5 14.0001C2.588 14.0001 2.67443 13.9768 2.7505 13.9325L12.2505 8.43252C12.3263 8.38857 12.3892 8.32548 12.4329 8.24958C12.4766 8.17367 12.4996 8.08762 12.4996 8.00002C12.4996 7.91243 12.4766 7.82637 12.4329 7.75046C12.3892 7.67456 12.3263 7.61147 12.2505 7.56752L2.7505 2.06752C2.67451 2.02353 2.58827 2.00033 2.50046 2.00024C2.41266 2.00016 2.32638 2.02321 2.2503 2.06706C2.17423 2.11091 2.11105 2.17402 2.06711 2.25004C2.02317 2.32606 2.00002 2.41231 2 2.50012V13.5001C2 13.6327 2.05268 13.7599 2.14645 13.8537Z"/>
                </svg>
            </button>
            <button class="player__controls__repeat">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 4H3.415L5.205 2.205L4.5 1.5L1.5 4.5L4.5 7.5L5.205 6.795L3.415 5H13.5V13H3.5V9.5H2.5V13C2.5 13.2652 2.60536 13.5196 2.79289 13.7071C2.98043 13.8946 3.23478 14 3.5 14H13.5C13.7652 14 14.0196 13.8946 14.2071 13.7071C14.3946 13.5196 14.5 13.2652 14.5 13V5C14.5 4.73478 14.3946 4.48043 14.2071 4.29289C14.0196 4.10536 13.7652 4 13.5 4Z"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MusicPlayer',
};
</script>

<style lang="scss" scoped>
    .player {
        background-color: #1C1C1C;
        border-radius: 16px;
        color: white;
        padding: 12px 24px;
        max-width: 480px;
        margin: 80px auto;

        &__content {
            display: flex;
            gap: 52px;

            &__sound {
                align-items: start;
                display: flex;
                gap: 24px;
                flex: 1;
                flex-direction: column;
                justify-content: center;

                &__track {
                    &__title {
                        font-size: 28px;
                        margin: 0;
                    }

                    &__artist {
                        font-size: 18px;
                        color: #959595;
                    }
                }

                &__timer {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;

                    &__bar {
                        background: #525252;
                        border-radius: 200px;
                        overflow: hidden;
                        height: 4px;
                        width: 100%;

                        &__progress {
                            background-color: #D9D9D9;
                            height: 4px;
                            transition: all 0.3s ease-in;
                            width: 20%;
                        }
                    }

                    &__time {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        &__start, &__end {
                            color: #959595;
                        }
                    }
                }
            }

            &__img {
                border-radius: 16px;
                height: 134px;
                object-fit: cover;
                width: 134px;
            }
        }

        &__controls {
            align-items: center;
            display: flex;
            gap: 8px;
            margin-top: 8px;
            justify-content: center;
            

            button {
                border: none;
                cursor: pointer;
                color: #fff; // Color inicial del SVG
                display: flex;
            }

            &__play {
                background-color: #66D56E;
                border-radius: 200%;
                padding: 16px;

                &:hover {
                    background-color: #3e3d3d;
                    color: #66D56E; // Cambia el color del SVG
                }
            }

            &__aleatory, &__prev, &__next, &__repeat {
                background-color: #1C1C1C;
                border-radius: 200%;
                padding: 12px;

                &:hover {
                    color: #66D56E;
                }
            }
        }
    }
</style>