<template>
    <section class="row-card-ikea">
      <div class="card-ikea">
        <img class="card-ikea__img" src="https://images.unsplash.com/photo-1600093463592-8e36ae95ef56?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
        <div class="card-ikea__content">
          <h3>Tienda de Café</h3>
          <p>Descubre el mejor lugar para disfrutar con amigos, trabajar o simplemente disfrutar de café.</p>
          <button>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
            </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'CardIkea'
  };
  </script>
  
<style lang="scss" scoped>
    .row-card-ikea {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px 20px;

        .card-ikea {
            background: #fff;
            border-radius: 24px;
            border: 1px solid #ebebeb;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            max-height: 70vh;
            max-width: 460px;
            flex-grow: 1;         // Permite que el elemento crezca para ocupar el espacio disponible
            flex-basis: 100%;     // Hace que el elemento inicialmente ocupe todo el ancho disponible
            text-align: left;
            min-width: 290px;     // Establece el ancho mínimo del elemento
            overflow: hidden;

            &:hover button {
                background-color: #A67A5B;
            }

            &:hover button svg {
                transform: rotate(45deg);   
                opacity: 0.8;
            }


            &:hover img{
                filter: grayscale(.8);
            }

            &__img {
                flex: 4;
                object-fit: cover;
                width: 100%;
            }

            &__content {
            padding: 32px;
            flex: 1;

                h3 {
                    color: #333;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 34px;
                    margin-bottom: 24px;
                    margin-top: 0;
                }

                p {
                    color: #333;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 32px;
                }

                button {
                    background-color: #333;
                    border-radius: 50%;
                    border: none;
                    color: #fff;
                    padding: 16px;
                    height: 56px;
                    width: 56px;

                    svg {
                        transition: 0.2s ease-in-out;
                    }
                }
            }
        }

    }

</style>