<template>
    <div class="visibility-settings">
        <div class="visibility-settings__container">
            <div class="visibility-settings__container__head">
                <h1>Visibility Settings</h1>
                <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z" fill="black"/>
                </svg>
            </div>
            <div class="visibility-settings__container__body">
                <p>Choose who can see your post by adjusting your audience settings to fit your preferences.</p>
                <div class="visibility-settings__container__body__selectors-item">
                    <div class="visibility-settings__container__body__selectors-item__icon-container">
                        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.38893 2.42133C8.9778 2.14662 8.49445 2 8 2C7.33696 2 6.70108 2.26339 6.23223 2.73223C5.76339 3.20107 5.5 3.83696 5.5 4.5C5.5 4.99445 5.64662 5.4778 5.92133 5.88893C6.19603 6.30005 6.58648 6.62048 7.04329 6.8097C7.50011 6.99892 8.00277 7.04843 8.48773 6.95196C8.97268 6.8555 9.41814 6.6174 9.76777 6.26777C10.1174 5.91814 10.3555 5.47268 10.452 4.98773C10.5484 4.50277 10.4989 4.00011 10.3097 3.54329C10.1205 3.08648 9.80005 2.69603 9.38893 2.42133ZM6.05551 1.58986C6.63108 1.20527 7.30777 1 8 1C8.92826 1 9.8185 1.36875 10.4749 2.02513C11.1313 2.6815 11.5 3.57174 11.5 4.5C11.5 5.19223 11.2947 5.86892 10.9101 6.4445C10.5256 7.02007 9.97893 7.46867 9.33939 7.73358C8.69985 7.99848 7.99612 8.0678 7.31719 7.93275C6.63825 7.7977 6.01461 7.46436 5.52513 6.97487C5.03564 6.48539 4.7023 5.86175 4.56725 5.18282C4.4322 4.50388 4.50152 3.80015 4.76642 3.16061C5.03133 2.52107 5.47993 1.97444 6.05551 1.58986ZM12 15H13V12.5C13 11.5717 12.6313 10.6815 11.9749 10.0251C11.3185 9.36875 10.4283 9 9.5 9H6.5C5.57174 9 4.6815 9.36875 4.02513 10.0251C3.36875 10.6815 3 11.5717 3 12.5V15H4V12.5C4 11.837 4.26339 11.2011 4.73223 10.7322C5.20107 10.2634 5.83696 10 6.5 10H9.5C9.8283 10 10.1534 10.0647 10.4567 10.1903C10.76 10.3159 11.0356 10.5001 11.2678 10.7322C11.4999 10.9644 11.6841 11.24 11.8097 11.5433C11.9353 11.8466 12 12.1717 12 12.5V15Z" fill="black"/>
                        </svg>
                    </div>
                    <label for="first-selector">
                        <strong>Everyone</strong>
                        <span>Anyone on this can see your post.</span>
                    </label>
                    <input type="radio" id="first-selector" name="visibility">
                </div>
                <div class="visibility-settings__container__body__selectors-item">
                    <div class="visibility-settings__container__body__selectors-item__icon-container">
                        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.38893 2.42133C8.9778 2.14662 8.49445 2 8 2C7.33696 2 6.70108 2.26339 6.23223 2.73223C5.76339 3.20107 5.5 3.83696 5.5 4.5C5.5 4.99445 5.64662 5.4778 5.92133 5.88893C6.19603 6.30005 6.58648 6.62048 7.04329 6.8097C7.50011 6.99892 8.00277 7.04843 8.48773 6.95196C8.97268 6.8555 9.41814 6.6174 9.76777 6.26777C10.1174 5.91814 10.3555 5.47268 10.452 4.98773C10.5484 4.50277 10.4989 4.00011 10.3097 3.54329C10.1205 3.08648 9.80005 2.69603 9.38893 2.42133ZM6.05551 1.58986C6.63108 1.20527 7.30777 1 8 1C8.92826 1 9.8185 1.36875 10.4749 2.02513C11.1313 2.6815 11.5 3.57174 11.5 4.5C11.5 5.19223 11.2947 5.86892 10.9101 6.4445C10.5256 7.02007 9.97893 7.46867 9.33939 7.73358C8.69985 7.99848 7.99612 8.0678 7.31719 7.93275C6.63825 7.7977 6.01461 7.46436 5.52513 6.97487C5.03564 6.48539 4.7023 5.86175 4.56725 5.18282C4.4322 4.50388 4.50152 3.80015 4.76642 3.16061C5.03133 2.52107 5.47993 1.97444 6.05551 1.58986ZM12 15H13V12.5C13 11.5717 12.6313 10.6815 11.9749 10.0251C11.3185 9.36875 10.4283 9 9.5 9H6.5C5.57174 9 4.6815 9.36875 4.02513 10.0251C3.36875 10.6815 3 11.5717 3 12.5V15H4V12.5C4 11.837 4.26339 11.2011 4.73223 10.7322C5.20107 10.2634 5.83696 10 6.5 10H9.5C9.8283 10 10.1534 10.0647 10.4567 10.1903C10.76 10.3159 11.0356 10.5001 11.2678 10.7322C11.4999 10.9644 11.6841 11.24 11.8097 11.5433C11.9353 11.8466 12 12.1717 12 12.5V15Z" fill="black"/>
                        </svg>
                    </div>
                    <label for="second-selector">
                        <strong>Only Followers </strong>
                        <span>Only people who follow you can see this post.</span>
                    </label>
                    <input type="radio" id="second-selector" name="visibility">
                </div>
                <div class="visibility-settings__container__body__selectors-item">
                    <div class="visibility-settings__container__body__selectors-item__icon-container">
                        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.38893 2.42133C8.9778 2.14662 8.49445 2 8 2C7.33696 2 6.70108 2.26339 6.23223 2.73223C5.76339 3.20107 5.5 3.83696 5.5 4.5C5.5 4.99445 5.64662 5.4778 5.92133 5.88893C6.19603 6.30005 6.58648 6.62048 7.04329 6.8097C7.50011 6.99892 8.00277 7.04843 8.48773 6.95196C8.97268 6.8555 9.41814 6.6174 9.76777 6.26777C10.1174 5.91814 10.3555 5.47268 10.452 4.98773C10.5484 4.50277 10.4989 4.00011 10.3097 3.54329C10.1205 3.08648 9.80005 2.69603 9.38893 2.42133ZM6.05551 1.58986C6.63108 1.20527 7.30777 1 8 1C8.92826 1 9.8185 1.36875 10.4749 2.02513C11.1313 2.6815 11.5 3.57174 11.5 4.5C11.5 5.19223 11.2947 5.86892 10.9101 6.4445C10.5256 7.02007 9.97893 7.46867 9.33939 7.73358C8.69985 7.99848 7.99612 8.0678 7.31719 7.93275C6.63825 7.7977 6.01461 7.46436 5.52513 6.97487C5.03564 6.48539 4.7023 5.86175 4.56725 5.18282C4.4322 4.50388 4.50152 3.80015 4.76642 3.16061C5.03133 2.52107 5.47993 1.97444 6.05551 1.58986ZM12 15H13V12.5C13 11.5717 12.6313 10.6815 11.9749 10.0251C11.3185 9.36875 10.4283 9 9.5 9H6.5C5.57174 9 4.6815 9.36875 4.02513 10.0251C3.36875 10.6815 3 11.5717 3 12.5V15H4V12.5C4 11.837 4.26339 11.2011 4.73223 10.7322C5.20107 10.2634 5.83696 10 6.5 10H9.5C9.8283 10 10.1534 10.0647 10.4567 10.1903C10.76 10.3159 11.0356 10.5001 11.2678 10.7322C11.4999 10.9644 11.6841 11.24 11.8097 11.5433C11.9353 11.8466 12 12.1717 12 12.5V15Z" fill="black"/>
                        </svg>
                    </div>
                    <label for="third-selector">
                        <strong>Specific People</strong>
                        <span>Only the people you choose can see this post.</span>
                    </label>
                    <input type="radio" id="third-selector" name="visibility">
                </div>
            </div>
            <div class="visibility-settings__container__footer">
                <button>Save changes</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name: 'VisibilitySettings',
};
</script>

<style lang="scss" scoped>
 .visibility-settings {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 80vh;

    &__container {
        background-color: #fff;
        border-radius: 12px;
        padding: 16px;
        width: 400px;

        &__head {
            border-bottom: 1px solid #DEE2E3;
            display: flex;
            align-items: center;

            h1 {
                font-size: 18px;
                font-weight: 500;
                flex: 1;
            }

            svg {
                height: 32px;
                width: 32px;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 24px 0;

            p {
                font-size: 14px;
                font-weight: 200;
            }

            &__selectors-item {
                align-items: center;
                border: 1px solid #E0E4E5;
                background-color: #F8FBF8;
                border-radius: 8px;
                display: flex;
                padding: 8px;
                gap: 12px;

                &:hover {
                    background-color: white;
                }

                &__icon-container {
                    background-color: white;
                    border-radius: 200px;
                    box-shadow: rgb(171, 169, 171) 2px 3px 9px -3px;
                    height: 16px;
                    padding: 12px;
                    width: 16px;

                    svg {
                        height: 16px;
                        width: 16px;
                    }
                }

                label {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    flex: 1;

                    strong {
                        font-weight: 500;
                    }

                    span {
                        font-weight: 300;
                    }
                }

                input[type=radio] {
                    border: 0px;
                    width: 20px;
                    height: 20px;
                    accent-color: #D2DBDA;
                }
            }
        }

        &__footer {
            display: flex;
            padding: 16px 8px;
            justify-content: flex-end;

            button {
                background-color: #14474B;
                border-radius: 8px;
                border: none;
                color: white;
                cursor: pointer;
                padding: 12px 16px;

                &:hover {
                    background-color: #133B3F;
                }
            }
        }
    }
    
}

</style>