    <template>
        <div class="cta-container">
        <button class="cta-button" ref="ctaButton" @mousemove="handleMouseMove" @mouseleave="resetMouseEffect">
            <div class="hover-effect-container" :style="gradientPosition">
            <div class="hover-gradient-large"></div>
            <div class="hover-gradient-small"></div>
            </div>
            <span>EFECTO HOVER</span>
        </button>
        </div>
    </template>
  
  <script>
  export default {
    name: 'GlowButtonEffect',
    data() {
        return {
            gradientPosition: {
                transform: "translateX(50%) translateY(50%)"
            },
        }
    },
    methods: {
        handleMouseMove(event) {
            const button = this.$refs.ctaButton;
            const rect = button.getBoundingClientRect();
            const x = event.clientX - rect.left - rect.width / 2;
            const y = event.clientY - rect.top - rect.height / 2;
            this.gradientPosition.transform = `translateX(${x}px) translateY(${y}px)`;
        },
        resetMouseEffect() {
            this.gradientPosition.transform = "translateX(50%) translateY(50%)";
        }
    }
  };
  </script>
  
<style lang="scss" scoped>
     .cta-container {
        align-items: center;
        border: none;
        display: flex;
        justify-content: center;
        height: 25vh;
        border-radius: 24px;
        margin: 20px 0;

        .cta-button {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 16px 32px;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            background: #d1d1d1;
            border: 1px solid rgba(255, 255, 255, 0.6);
            border-radius: 999px;
            overflow: hidden;
            cursor: pointer;

        span {
            z-index: 1;
        }

        .hover-effect-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 0;
            transform: translateX(50%) translateY(50%);
            transition: transform 0.1s linear;
        }

        .hover-gradient-large {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 240px; /* Aumentar ligeramente el tamaño para mejor difusión */
            height: 240px;
            background: radial-gradient(
            50% 50% at 50% 50%,
            #fffff5a1 5%,
            #ffaa8161 30%,
            #ffda9f75 45%,
            rgb(255 170 129 / 24%) 60%,
            rgba(210, 106, 58, 0) 100%
            );
            transform: translate(-50%, -50%);
        }

        .hover-gradient-small {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 120px; /* Aumentar para balancear con el gradiente grande */
            height: 120px;
            background: radial-gradient(
            43.3% 44.23% at 50% 49.51%, 
            #fffff7 25%, 
            #fffacd 50%, 
            #f4d2bf 70%, 
            hsla(15, 5%, 83%, 0) 100%
            );
            transform: translate(-50%, -50%);
            filter: blur(8px); /* Incrementar el blur para suavizar bordes */
            opacity: 0.8; /* Reducir ligeramente la opacidad */
        }
        }
    }

</style>