<template>
    <div class="main-container">
        <section class="info-team">
        <div class="info-team__header">
            <h5>Team Info</h5>
            <label class="info-team__header__switch">
                <input type="checkbox">
                <span class="slider"></span>
            </label>
        </div>
        <div class="info-team__section">
            <p>Thumnail</p>
            <span>150x150px JPEG, PNG Image</span>
            <img src="https://images.unsplash.com/photo-1571513721963-d855fd8df4c2?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
        </div>
        <div class="info-team__section">
            <p>Team Name</p>
            <span>Product Manager</span>
            <div class="info-team__section__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.625 1.625L15.875 3.875C16.375 4.375 16.375 5.125 15.875 5.625L6.5 15H2.5V11L11.875 1.625C12.375 1.125 13.125 1.125 13.625 1.625ZM15 4.75L12.75 2.5L10.875 4.375L13.125 6.625L15 4.75ZM3.75 11.5V13.75H6L12.25 7.5L10 5.25L3.75 11.5ZM1.25 17.5V16.25H18.75V17.5H1.25Z"/>
                </svg>
            </div>
        </div>
        <div class="info-team__section">
            <p>Description</p>
            <span>This is the job description for a product manager.</span>
            <div class="info-team__section__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.625 1.625L15.875 3.875C16.375 4.375 16.375 5.125 15.875 5.625L6.5 15H2.5V11L11.875 1.625C12.375 1.125 13.125 1.125 13.625 1.625ZM15 4.75L12.75 2.5L10.875 4.375L13.125 6.625L15 4.75ZM3.75 11.5V13.75H6L12.25 7.5L10 5.25L3.75 11.5ZM1.25 17.5V16.25H18.75V17.5H1.25Z"/>
                </svg>
            </div>
        </div>
        <div class="info-team__section">
            <p>View as</p>
            <span>150x150px JPEG, PNG Image</span>
            <div class="info-team__section__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.625 1.625L15.875 3.875C16.375 4.375 16.375 5.125 15.875 5.625L6.5 15H2.5V11L11.875 1.625C12.375 1.125 13.125 1.125 13.625 1.625ZM15 4.75L12.75 2.5L10.875 4.375L13.125 6.625L15 4.75ZM3.75 11.5V13.75H6L12.25 7.5L10 5.25L3.75 11.5ZM1.25 17.5V16.25H18.75V17.5H1.25Z"/>
                </svg>
            </div>
        </div>
        <div class="info-team__section">
            <p>Skills</p>
            <span>150x150px JPEG, PNG Image</span>
            <div class="info-team__section__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.625 1.625L15.875 3.875C16.375 4.375 16.375 5.125 15.875 5.625L6.5 15H2.5V11L11.875 1.625C12.375 1.125 13.125 1.125 13.625 1.625ZM15 4.75L12.75 2.5L10.875 4.375L13.125 6.625L15 4.75ZM3.75 11.5V13.75H6L12.25 7.5L10 5.25L3.75 11.5ZM1.25 17.5V16.25H18.75V17.5H1.25Z"/>
                </svg>
            </div>
        </div>
    </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InfoTeam'
  };
  </script>
  
<style lang="scss" scoped>
 
 .main-container {
    align-items: center;
    display: flex;
    height: 80vh;
    justify-content: center;
    width: 100%;

    .info-team {
        border-radius: 16px;
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.22);
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 32px 20px;
        min-width: 700px;
        max-width: 780px;

        &__header {
            align-items: center;
            display: flex;
            justify-content: space-between;

            h5 {
                font-size: 16px;
                margin: 0dvh;
            }

            /* From Uiverse.io by arghyaBiswasDev */ 
            /* The switch - the box around the slider */
            &__switch {
                font-size: 17px;
                position: relative;
                display: inline-block;
                width: 3.5em;
                height: 2em;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #fff;
                    border: 1px solid #adb5bd;
                    transition: .4s;
                    border-radius: 30px;
                }

                .slider:before {
                    position: absolute;
                    content: "";
                    height: 1.4em;
                    width: 1.4em;
                    border-radius: 20px;
                    left: 0.27em;
                    bottom: 0.25em;
                    background-color: #adb5bd;
                    transition: .4s;
                }

                input:checked + .slider {
                    background-color: #007bff;
                    border: 1px solid #007bff;
                }

                input:focus + .slider {
                    box-shadow: 0 0 1px #007bff;
                }

                input:checked + .slider:before {
                    transform: translateX(1.4em);
                    background-color: #fff;
                }
            }

        }

        &__section {
            align-items: center;
            display: flex;
            color: #7A829B;
            justify-content: space-between;
            width: 100%;

            &__icon {
                display: flex;
                justify-content: center;
                width: 64px;

                svg {
                    cursor: pointer;
                    fill: #4083F7;

                    &:hover {
                        fill: #1257CD;
                    }
                }
            }

            p, span {
                font-size: 14px;
            }

            p {
                flex-grow: 1;
                max-width: 96px;
                text-align: left;
            }

            span {
                flex: 2;
                text-align: left;
            }

            img {
                border-radius: 50%;
                height: 64px;
                object-fit: cover;
                width: 64px;
            }

        }
    }
 }
    
</style>