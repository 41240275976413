<template>
    <div class="modal" v-if="isVisible" @click="close">
      <div class="modal-content" @click.stop>
        <span class="close" @click="close">&times;</span>
        <h2> {{ title }} </h2>
        <img v-if="hasSlotContent" :src="imageSrc" alt="Imagen seleccionada">
        <p>{{ description }}</p>
        <!-- Agrega el slot aquí -->
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      imageSrc: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      }
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que el modal esté sobre el resto del contenido */
  }
  
  .modal-content {
    background-color: #F8FBF8;
    border-radius: 10px;
    height: 90vh;
    overflow: auto;
    padding: 20px;
    position: relative;
    text-align: center;
    width: 90vw;

    img {
      border-radius: 8px;
      height: 78vh;
      object-fit: cover;
      width: 100%;     
    }
    
  }
  
  .close {
    position: absolute;
    color: #333;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;

    &:hover {
      opacity: 80%;
    }
  }
  </style>