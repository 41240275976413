<template>
  <div>
    <div v-if="isLoading" class="loading-bar">
      <div class="loading-bar__progress" :style="{ width: loadingPercentage + '%' }"></div>
    </div>
    <div v-if="!isLoading" class="home-page">
    <section class="home-page__head">
      <p class="home-page__head__job">Product Designer</p>
      <h1 class="home-page__head__title">Camilo Metaute</h1>
      <span class="home-page__head__location">Medellín, Colombia</span>
      <a class="home-page__head__button" href="https://www.linkedin.com/in/milometaute/" target="_blank">Contactar en Linkedin</a>
      <div class="home-page__head__container-profile">
        <img class="home-page__head__container-profile__profile" :src="miloProfile" alt="">
        <div class="home-page__head__container-profile__experience">
          <p><strong>+10 años</strong> diseñando experiencias digitales.</p>
        </div>
      </div>
    </section>
    <section class="home-page__body">
      <div class="home-page__body__columns" v-for="(item, index) in items" :key="index">
        <div class="home-page__body__columns__content">
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
        <img :src="item.image" alt="" class="home-page__body__columns__image">
      </div>
    </section>

    <section class="home-page__footer">
      <h5>Camilo Metaute</h5>
    </section>
  </div>
  </div>
  
</template>

<script>
import miloProfile from '@/assets/milo-profile.png';

export default {
  name: 'HomePage',
  components: {
    
  },
  data() {
    return {
      miloProfile,
      message: 'Hello, this is a dynamic message from Home',
      isLoading: true,
      loadingPercentage: 0,
      items: [
        { title: 'Responsive Layout', description: 'Jugando un poco en Figma con un autolayout que se adapta en diferentes dispostivos.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15496154/media/9038def78fc75602977974f656a797df.gif' },
        { title: 'Composición Hero', description: 'Construyendo en Figma, usando 50% del layout un Bento Grid y texto en el otro 50%.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15459826/media/c3af271b58e97cbba375e4134f0ec2ad.png' },
        { title: 'Presentación Producto', description: 'Una transición para cargar estilos de cascos y explorar sus características. ', image: 'https://cdn.dribbble.com/users/916693/screenshots/13263333/media/cbbc013b209b92b1774da8915ffbed1f.gif' },
        { title: 'Idea SideProject', description: 'Soy fan del Downhill MTB, muchos riders quisieran ver las diferencias las bicis ganadoras de la copa mundo, un histórico por año y sus características.', image: 'https://cdn.dribbble.com/users/916693/screenshots/10975535/media/7bac0f195de27f66df377276ffd04d9a.jpg' },
        { title: 'Hero diseño interiores', description: 'Un hero interactivo que permite descubrir con interacción las imágenes de diseños de interiores.', image: 'https://cdn.dribbble.com/users/916693/screenshots/11011972/media/74b8742568f13b554883fb387f7e848a.jpg' },
        { title: 'Parrallax Hero', description: 'Una transición en scroll usando el efecto Parrallax.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15096289/media/406a840f996e7cc75b185bea5caddb61.gif' },
      ],
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.loadingPercentage < 100) {
        this.loadingPercentage += 10;
      } else {
        this.isLoading = false;
        clearInterval(interval);

        // Emitir evento al componente padre
        this.$emit('loading-complete', false);
      }
    }, 300);
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #222;

  &__progress {
    height: 100%;
    background-color: #e5f04b;
    transition: all 0.3s ease-in-out;
  }
}

.home-page {
  text-align: center;
  margin-top: 172px;
  
  &__head {
    background-image: url('/src/assets/background-desk.png'); // Ruta de tu imagen
    background-size: cover; // Asegura que el fondo cubra todo el contenedor
    background-position: center; // Centra la imagen en el contenedor
    background-repeat: no-repeat; // Evita que la imagen se repita
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 100%; // Asegura que el contenedor tenga un ancho completo

     @media (max-width: 780px) {
      gap: 12px;
    }

    &__job {
      color: #DDFF00;
      font-size: 24px;
      width: auto;
      text-align: center;
      position: relative;

      @media (max-width: 780px) {
        font-size: 18px;
      }

      &::before,
      &::after {
        content: ''; // Necesario para los pseudos
        position: absolute;
        top: 50%; // Alineación vertical centrada
        transform: translateY(-50%);
        opacity: .4;
        width: 40px; // Ancho de las líneas
        height: 2px; // Grosor de las líneas
        background-color: #DDFF00; // Mismo color que el texto
      }

      &::before {
        left: -50px; // Ajusta la posición a la izquierda
      }

      &::after {
        right: -50px; // Ajusta la posición a la derecha
      }
    }

    &__title {
      background: linear-gradient(90deg, #B1AFA6, #EDEBE3);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      flex-basis: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 120px;
      margin: 0;

      @media (max-width: 780px) {
        font-size: 52px;
        width: 80%;
      }
    }

    &__location {
      font-size: 32px;
      color: #B1AFA6;

      @media (max-width: 780px) {
        font-size: 20px;
      }
    }

    &__button {
      background-color: #DDFF00;
      border-radius: 48px;
      color: #0F0F0F;
      font-size: 18px;
      padding: 12px 24px;
      text-decoration: none;

      &:hover {
        opacity: 0.5;
      }

      @media (max-width: 780px) {
        margin-bottom: 10%;
      }
    }

    &__container-profile {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 80%;

      &__profile {
        width: 500px;

        @media (max-width: 780px) {
          width: 290px;
        }
      }

      &__experience {
        align-items: center;
        border-radius: 8px;
        border: 1px solid #4C4A4A;
        display: flex;
        color: #B1AFA6;
        font-size: 32px;
        justify-content: center;
        overflow: hidden;
        padding: 24px 24px;
        width: 60%;

        @media (max-width: 780px) {
          width: 95%;
        }
        
        p {
          margin: 0;
        }

        strong {
          color: white;
        }
      }
    }

  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 96px;
    margin-top: 124px;
    padding: 0px 72px;

    &__columns {
      display: flex;
      gap: 2%;
      flex-wrap: wrap;
      text-align: left;
      width: 100%;

      &__content {
        color: white;
        width: 33%;

        @media (max-width: 780px) {
          width: 100%;
        }
      }

      img {
        border-radius: 20px;
        width: 65%;

        @media (max-width: 780px) {
          border-radius: 8px;
          width: 100%;
        }
      }

      h3 {
        font-size: 32px;
        margin: 32px 0 0 0;
        color: #cecece;
      }
    }

    &__news-item {
      margin-top: 30px;
    }

  }

  &__footer {
    background-color: #2f2f2f;
    padding: 48px;
    margin-top: 40px;
    border-radius: 16px;
    color: white;
  
    h3 {
      text-align: center;
      font-size: 32px;
      margin-bottom: 0;
    }

    h5 {
      font-size: 20px;
    }

    &__experiment {
      border-radius: 16px;
      border: 1px solid white;
      margin-top: 56px;
    }
  }

}
</style>