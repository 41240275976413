<template>
  <div id="app">
   <div v-if="!isLoading" class="nav">
    <div class="links">
      <router-link to="/">Inicio</router-link>
      <router-link to="/playground">Taller de exploraciones</router-link>
    </div>
   </div>
    <router-view @loading-complete="handleLoadingComplete"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isLoading: true, // Estado inicial
    };
  },
  methods: {
    handleLoadingComplete(loadingState) {
      this.isLoading = loadingState; // Actualiza el estado desde el hijo
    },
  },
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

body {
  font-family: 'Euclid Circular A', sans-serif;
  background-color: #202020;
  height: 100vh;
  padding: 0 16px;

  .nav {
    align-items: center;
    backdrop-filter: blur(20px);
    border: 1px solid #4f4f4f;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 16px;
    padding: 16px 12px;
    margin-left: -134px;
    left: 50%;
    width: 240px;
    z-index: 1;

    .links {
      display: flex;
      gap: 16px;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          color: #DDFF00;
        }
      }
    }


    h3 {
      color: white;
    }
  }
}
</style>